import * as React from "react"
import BasicPage from "../components/commons/basic-page"
import Layout from "../components/layout/en"

const PoliticaDePrivacidad = (props, Content) => {

  return (
    <Layout robots="block" pageId="privacy" location={props.location} metaTitle="Política de privacidad | Crequs">
        <BasicPage 
            title="Privacy policy">
            <h2>INTRODUCCI&Oacute;N</h2>
            <p>Su privacidad es importante para nosotros, as&iacute; que mediante este documento le explicamos qu&eacute; datos recopilamos de los usuarios, como los utilizamos y para qu&eacute;, entre otras indicaciones. Los mayores de 14 a&ntilde;os podr&aacute;n registrarse como usuarios sin el previo consentimiento de sus padres, tutores o representantes legales. En caso de menores de 14 a&ntilde;os o identificados como &laquo;incapaces legalmente&raquo;, siempre necesitaremos del consentimiento paterno, o del tutor/a o representante legal.</p>
            <p>Para su informaci&oacute;n, nunca solicitaremos m&aacute;s informaci&oacute;n de la que realmente necesitaremos para los servicios requeridos; nunca la compartiremos con terceros, excepto por obligaci&oacute;n legal o con su autorizaci&oacute;n previa; y nunca utilizaremos sus datos para otra finalidad no indicada previamente.</p>
            <p>Como usuario, deber&aacute; leer atentamente esta pol&iacute;tica de privacidad de manera regular, y siempre que introduzca sus datos personales, ya que este documento puede sufrir modificaciones pues el prestador puede modificar cualquier tipo de informaci&oacute;n que pudiera aparecer en la web para cumplir con la normativa o actualizar la pol&iacute;tica, y sin que exista la obligaci&oacute;n de pre-avisar o poner en conocimiento de los usuarios estas obligaciones, siendo suficientes la publicaci&oacute;n en el sitio web del prestador.</p>
            <h2>RESPONSABLE DEL TRATAMIENTO</h2>
            <p><strong>Identidad: Paolo Bergamelli</strong>&nbsp;(tambi&eacute;n el prestador)<br /><strong>NIF:</strong>&nbsp;Y2729791G<br /><strong>Direcci&oacute;n postal:</strong>&nbsp;Calle Rollo 5, 28005, Madrid (Espa&ntilde;a)<br /><strong>Correo electr&oacute;nico: </strong>info@crequs.com<br /><strong>Paolo Bergamelli</strong>, como responsable del Sitio web, de conformidad con lo que dispone el Reglamento (UE) 2016/679 de 27 de abril de 2016 (RGPD), Ley Org&aacute;nica 3/2018 de 5 de diciembre (LOPDGDD) y dem&aacute;s normativa legal vigente en materia de protecci&oacute;n de datos personales, y por la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico (LSSICE), le informa de que tiene implementadas las medidas de seguridad necesarias, de &iacute;ndole t&eacute;cnica y organizativas, para garantizar y proteger la confidencialidad, integridad y disponibilidad de los datos introducidos.</p>
            <p><strong>M&Eacute;TODO DE OBTENCI&Oacute;N DE LOS DATOS y DATOS QUE RECAUDAMOS:</strong>&nbsp;Los datos que recogemos y c&oacute;mo las obtenemos es la siguiente:</p>
            <ol>
            <li>mediante el&nbsp;<strong>formulario de contacto</strong>: nombre, email, mensaje,</li>
            <li>mediante el email</li>
            </ol>
            <p>No se tratan categor&iacute;as de datos especialmente protegidos.</p>
            <h2>FINALIDAD DEL TRATAMIENTO</h2>
            <p>Sus datos personales s&oacute;lo se utilizar&aacute;n para las siguientes finalidades, prestando su consentimiento para cada una de las finalidades en el apartado correspondiente de la web:</p>
            <ol>
            <li><strong>formulario de contacto:</strong>&nbsp;para responder a les consultas y / o proporcionar informaciones requeridas para el Usuario;</li>
            <li>Otras finalidades:</li>
            </ol>
            <ul>
            <li>
            <ul>
            <li>Realizar las gestiones comerciales, administrativas, fiscales, contables que sean necesarias en virtud de la legislaci&oacute;n vigente;</li>
            <li>Conocer su opini&oacute;n y mejorar los servicios prestados por la web;</li>
            <li>Realizaci&oacute;n de an&aacute;lisis de perfiles y de usabilidad de la web, a trav&eacute;s de las cookies (mencionadas en la pol&iacute;tica de cookies);</li>
            </ul>
            </li>
            </ul>
            <p>Puede revocar en cualquier momento su consentimiento remitiendo un escrito con el asunto &laquo;Baja&raquo; en el mail rese&ntilde;ado.</p>
            <p>De acuerdo con la LSSICE, el prestador no realiza pr&aacute;cticas de SPAM, por lo tanto, no env&iacute;a correos comerciales por e-mail que no hayan sido previamente solicitados o autorizados por el Usuario. En consecuencia, en todas las comunicaciones que recibir&aacute; del prestador, el usuario tiene la posibilidad de revocar su consentimiento expreso para recibir nuestras comunicaciones.</p>
            <p>No trataremos sus datos personales para ning&uacute;na otra finalidad de las descritas excepto por obligaci&oacute;n legal o requerimiento judicial.</p>
            <p>No ser&aacute; objeto de decisiones basadas en tratamientos automatizados que produzcan efectos sobre sus datos.</p>
            <h2>LEGITIMACI&Oacute;N DEL TRATAMIENTO</h2>
            <p>La base legal para el tratamiento de los datos es su consentimiento otorgado en el momento de dar sus datos en el formulario correspondiente para llevar a cabo las finalidades, en el momento de marcaci&oacute;n de la casilla de verificaci&oacute;n.</p>
            <p>La oferta prospectiva o comercial de productos y servicios est&aacute; basada en el consentimiento que se le solicita, sin que en ning&uacute;n caso la retirada de este consentimiento condicione la ejecuci&oacute;n del contrato de suscripci&oacute;n.</p>
            <p>El no facilitar los datos personales solicitados o el no aceptar la presente pol&iacute;tica de privacidad supone la imposibilidad de conseguir las finalidades anteriormente descritas.</p>
            <p>En los casos en que haya una previa relaci&oacute;n contractual entre las partes, la legitimaci&oacute;n para el desarrollo de las obligaciones administrativas, fiscales y contables que sean necesarias en virtud de la legislaci&oacute;n vigente ser&aacute; la&nbsp;<strong>existencia previa de la relaci&oacute;n comercial</strong>&nbsp;establecida entre las partes.&nbsp;</p>
            <h2>PLAZO DE CONSERVACI&Oacute;N DE LOS DATOS:</h2>
            <p>Sus datos se conservar&aacute;n mientras dure la relaci&oacute;n comercial con nosotros o ejerce su derecho de cancelaci&oacute;n, oposici&oacute;n o revocaci&oacute;n del consentimiento. Aun habiendo ejercido sus derechos de forma correcta, deberemos conservar debidamente bloqueados sus datos mientras duren los plazos legalmente previstos en las disposiciones aplicables.</p>
            <h2>DESTINATARIOS DE CESIONES O TRANSFERENCIAS:</h2>
            <p>El prestador no lleva a cabo ninguna cesi&oacute;n o comunicaci&oacute;n de datos ni dentro ni fuera de la UE. El prestador &uacute;nicamente facilitar&aacute; informaci&oacute;n a las fuerzas y cuerpos de seguridad bajo orden judicial o por obligaci&oacute;n de una norma legal, sin perjuicio de poder bloquear o cancelar su cuenta si puede haber indicios de la comisi&oacute;n de alg&uacute;n delito por parte del usuario. La informaci&oacute;n facilitada ser&aacute; s&oacute;lo la que disponga en ese momento el prestador.</p>
            <p>La informaci&oacute;n que nos proporcione tanto a trav&eacute;s de este sitio web ser&aacute; alojada en los servidores de prestador, contratados a la empresa que presta el servicio de alojamiento de la web. El tratamiento de los datos de la entidad se encuentra regulado mediante un contrato de encargado del tratamiento entre el prestador y esta empresa.</p>
            <h2>DERECHOS DE LAS PERSONAS INTERESADAS:</h2>
            <p>Como usuario-interesado, puede solicitar el ejercicio de los siguientes derechos ante prestador presentando un escrito a la direcci&oacute;n postal del encabezamiento o enviando un correo electr&oacute;nico a info@crequs.com, indicando como Asunto: &laquo;PROTECCI&Oacute;N DE DATOS: DERECHOS DE LOS INTERESADOS &laquo;, y adjuntando fotocopia de su DNI o cualquier medio an&aacute;logo en derecho, tal como indica la ley.</p>
            <p>Derechos:</p>
            <ul>
            <li><strong>Derecho de acceso</strong>: permite al interesado conocer y obtener informaci&oacute;n sobre sus datos de car&aacute;cter personal sometidos a tratamiento.</li>
            <li><strong>Derecho de rectificaci&oacute;n o supresi&oacute;n</strong>: permite corregir errores y modificar los datos que resulten ser inexactos o incompletos</li>
            <li><strong>Derecho de cancelaci&oacute;n</strong>: permite que se supriman los datos que sean inadecuados o excesivos</li>
            <li><strong>Derecho de oposici&oacute;n</strong>: derecho del interesado a que no se lleve a cabo el tratamiento de sus datos de car&aacute;cter personal o se cese en el mismo.</li>
            <li><strong>Limitaci&oacute;n del tratamiento</strong>: conlleva el marcado de los datos personales conservados, con el fin de limitar su futuro tratamiento, para el ejercicio o la defensa de reclamaciones.</li>
            <li><strong>Portabilidad de los datos</strong>: facilitaci&oacute;n de los datos objeto de tratamiento al interesado, para que &eacute;ste pueda transmitir a otro responsable, sin impedimentos.</li>
            <li><strong>Derecho a no ser objeto de decisiones individuales automatizadas (incluida la elaboraci&oacute;n de perfiles):</strong>&nbsp;derecho a no ser objeto de una decisi&oacute;n basada en el tratamiento automatizado que produzca efectos o afecte significativamente</li>
            </ul>
            <p>Como usuario, tiene derecho a retirar el consentimiento dado en cualquier momento. La retirada del consentimiento no afectar&aacute; a la licitud del tratamiento efectuado antes de la retirada del consentimiento.</p>
            <p>Tambi&eacute;n tiene derecho a presentar una reclamaci&oacute;n ante la autoridad de control si considera que pueden haberse visto vulnerados sus derechos en relaci&oacute;n a la protecci&oacute;n de sus datos (www.aepd.es).</p>
            <h2>MEDIDAS DE SEGURIDAD:</h2>
            <p>Los datos que nos facilite se tratar&aacute;n de forma confidencial. El prestador ha adoptado todas las medidas t&eacute;cnicas y organizativas y todos los niveles de protecci&oacute;n necesarios para garantizar la seguridad en el tratamiento de los datos y evitar su alteraci&oacute;n, p&eacute;rdida, robo, tratamiento o acceso no autorizado, de acuerdo al estado de la tecnolog&iacute;a y naturaleza de los datos almacenados. Asimismo, se garantiza tambi&eacute;n que el tratamiento y registro en ficheros, programas, sistemas o equipos, locales y centros cumplen con los requisitos y condiciones de integridad y seguridad establecidas en la normativa vigente.&nbsp;</p>
            <h2>IDIOMA</h2>
            <p>El idioma aplicable a esta pol&iacute;tica de privacidad es el castellano. Por tanto, en caso de que hubiera alguna contradicci&oacute;n en alguna de las versiones facilitadas en otros idiomas, prevalecer&aacute; la versi&oacute;n en castellano.&nbsp;</p>
            <h2>AN&Aacute;LISIS DE PERFILES</h2>
            <p>La informaci&oacute;n facilitada en los diferentes formularios, durante la interacci&oacute;n con la web y cualquier otro dato facilitado durante la navegaci&oacute;n, incluyendo la informaci&oacute;n suministrada por las galletas en relaci&oacute;n a los h&aacute;bitos de navegaci&oacute;n, conlleva la aceptaci&oacute;n al tratamiento de estos datos, a fin ser segmentados o categorizados con el fin de aplicarlos a la actividad relacionada con la gesti&oacute;n de administraci&oacute;n general, informaci&oacute;n, comercializaci&oacute;n de espacios publicitarios, elaborar perfiles, an&aacute;lisis de propuestas solicitadas por los usuarios / clientes, estudio estad&iacute;stico los servicios y contenidos utilizados, gustos y preferencias, encuestas o formularios. Los datos utilizados en la elaboraci&oacute;n de estos perfiles no son datos que permitan identificar a los usuarios sino que s&oacute;lo muestran en una gr&aacute;fica el tipo de p&uacute;blico que interact&uacute;a con la web, edad, pa&iacute;s, g&eacute;nero, etc.</p>
            <p>El usuario que acceda a esta web, consiente expl&iacute;citamente la utilizaci&oacute;n de esta informaci&oacute;n gen&eacute;rica para tareas de elaboraci&oacute;n de perfiles, segmentaci&oacute;n y categorizaci&oacute;n de datos para las finalidades descritas en el apartado anterior.</p>
            <p>&nbsp;Los datos se conservar&aacute;n durante un plazo m&aacute;ximo de un a&ntilde;o transcurrido el cual se proceder&aacute; a la supresi&oacute;n de los datos garantizando un total respeto a la confidencialidad tanto en el tratamiento como en su posterior destrucci&oacute;n. En este sentido, transcurrido dicho plazo, y si desea continuar participando en los procesos de selecci&oacute;n del Responsable, le rogamos nos remita nuevamente su curr&iacute;culum.</p>
            <p>Los datos se podr&aacute;n tratar y / o comunicar a las empresas integrantes de nuestro grupo durante el tiempo de conservaci&oacute;n de su curr&iacute;culum y para los mismos fines antes informados.</p>
            <p>&nbsp;</p>
            <h2>SUSCRIPCI&Oacute;N AL BLOG</h2>
            <p>En caso de que el usuario se suscriba al Blog, le informamos que los datos aportados ser&aacute;n tratados para gestionar su suscripci&oacute;n al blog informativo con aviso de actualizaci&oacute;n y que se conservar&aacute;n mientras haya un inter&eacute;s mutuo para mantener el fin del tratamiento. Cuando ya no sea necesario para este fin, se suprimir&aacute;n con medidas de seguridad adecuadas para garantizar la seudonimizaci&oacute;n de los datos o destrucci&oacute;n total de los mismos. No se comunicar&aacute;n los datos a terceros, salvo obligaci&oacute;n legal.</p>
            <p>&nbsp;</p>
            <h2>PUBLICACI&Oacute;N DE SU TESTIMONIO</h2>
            <p>En caso de que el usuario quiera publicar su opini&oacute;n en la web, le informamos que los datos aportados ser&aacute;n tratados para atender las sugerencias propuestas, experiencias u opiniones respecto de los productos y / o servicios para ser publicadas en la p&aacute;gina web y as&iacute; poder ayudar a otros usuarios. Los datos se conservar&aacute;n mientras haya un inter&eacute;s mutuo para mantener el fin del tratamiento y cuando ya no sea necesario para tal fin, se suprimir&aacute;n con medidas de seguridad adecuadas para garantizar la pseudonimitzaci&oacute;n de los datos o destrucci&oacute;n total de los mismos. Los testigos se publicar&aacute;n en nuestra p&aacute;gina web. El &uacute;nico dato personal que se publicar&aacute; del testigo ser&aacute; su nombre.</p>
            <h2>CAMBIOS EN LA PRESENTE POL&Iacute;TICA DE PRIVACIDAD</h2>
            <p>El prestador se reserva el derecho a modificar la presente pol&iacute;tica para adaptarla a novedades legislativas o jurisprudenciales.</p>
            <h2>REVOCABILIDAD</h2>
            <p>El consentimiento prestado, tanto por el tratamiento como para la cesi&oacute;n de los datos de los interesados, es revocable en cualquier momento comunic&aacute;ndolo a prestador en los t&eacute;rminos establecidos en esta Pol&iacute;tica para el ejercicio de los derechos. Esta revocaci&oacute;n en ning&uacute;n caso tendr&aacute; car&aacute;cter retroactivo.&nbsp;</p>
            <h2 >LEGISLACI&Oacute;N</h2>
            <p>Con car&aacute;cter general las relaciones entre el prestador con los Usuarios de sus servicios telem&aacute;ticos, presentes en esta web, est&aacute;n sometidos a la legislaci&oacute;n y jurisdicci&oacute;n espa&ntilde;ola a la que se someten expresamente las partes, siendo competentes para la resoluci&oacute;n de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales de Barcelona.</p>
            <p>Con car&aacute;cter general las relaciones entre el prestador con los Usuarios de sus servicios telem&aacute;ticos, presentes en esta web, est&aacute;n sometidos a la legislaci&oacute;n y jurisdicci&oacute;n espa&ntilde;ola a la que se someten expresamente las partes, siendo competentes para la resoluci&oacute;n de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales de Barcelona.&nbsp;</p>
            <p><strong>&Uacute;ltima actualizaci&oacute;n: 14/10/2021</strong></p> 
        </BasicPage>
    </Layout>
  )
}

export default PoliticaDePrivacidad


